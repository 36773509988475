import { Injectable } from '@angular/core';
import {publicIp, publicIpv4, publicIpv6} from 'public-ip';

@Injectable({
  providedIn: 'root'
})
export class StoreDataServiceService {

  public IpAddress: string;
  
  fetchData(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      resolve(await publicIpv4());
    });
  }
  setIpAddress(ipvalue){
    this.IpAddress = ipvalue;
  }
  getIpAddress(): string{
    return this.IpAddress;
  }
}
