import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from 'src/app/service/constant.service';
import { LoginApiService } from 'src/app/service/login-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-personal-details',
  templateUrl: './verify-personal-details.component.html',
  styleUrls: ['./verify-personal-details.component.css']
})
export class VerifyPersonalDetailsComponent implements OnInit {

  emailproof : boolean;
  mobileproof : boolean;
  DeclarationList:[];
  constructor(
    public dialogRef: MatDialogRef<VerifyPersonalDetailsComponent>,
    public constantService :ConstantService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      // this.image = data.dataImage;
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
      this.emailproof =JSON.parse(this.constantService.getLocalStorageData('emailproof'));
      this.mobileproof =JSON.parse(this.constantService.getLocalStorageData('mobileproof'));
  }
  updatepersonaldata(){
    try{
      this.dialogRef.close('success');
    }
    catch(e){

    }
  }
  async getRelations() {
    await LoginApiService.getLoginApiService()
        .consentDeclarationList(environment.Activation_Ocpkey,'',"Web", '2')
        .then((response) => {
            this.DeclarationList = response.data;
        });
        
  }

}
