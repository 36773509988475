import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from './encryption.service';
@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  constructor(
    private cookies: CookieService,
    private encryptionService: EncryptionService,
  ) {}
  
  form = 'form';
  method = 'method';
  input = 'input';
  text ='text';
  type = 'type';
  post = 'post';
  action = 'action';
  name ='name';
  value = 'value';
  hidden = 'hidden';
  token = 'token';
  tokenSource = 'tokenSource';
  '5PAISA' = '5PAISA';
  SourceApp = 'SourceApp';
  Web = 'Web';
  LoginId = 'LoginId';
  IIFLAppSource ='IIFLAppSource';
  IndiaInfolineEasyRewards = 'IndiaInfolineEasyRewards';
  LOB = 'LOB';
  '5PTRADE' = '5PTRADE';
  userNotReg = 'User is not registered';
  generateOtpText = 'Generate OTP';
  verifyOtpText = 'Verify OTP';
  Success = 'Success';
  invalidMobileNumberError = 'Please enter valid mobile number';
  invalidDateFormatError = 'Please enter valid date format';
  dobMandatoryError = 'Date of birth is mandatory';
  redirectionURL = 'redirectionURL';
  RedeemPoints = 'RedeemPoints';
  LocalIP = '10.254.0.195';
  noOfDeviceBinded =3;
  checksumDateFormate = 'ddMMyy';
  showIncomePopup= false;
  showMobileEmailPopup = false;
  showNomineePopup = false;
  showFnoPopup = false;
// SET GET Encrypt Decrypt Cookie Data

  setEncryptedCookieData(cookieName, cookieValue) {
    try {
      this.cookies.set(cookieName, this.encryptionService.encryptUsingAES_256(cookieValue));
    } catch(e){
      console.log('Constant service .setEncryptedCookieData - ',e);
      return null;
    }
  }

  getDecryptedCookieData(cookieName) {
    try {
      return this.encryptionService.decryptUsingAES_256(this.cookies.get(cookieName));
    } catch(e){
      console.log('Constant service .getDecryptedCookieData - ',e);
      return null;
    }
  }

  // SET GET Cookie Data

  setCookieData(cookieName, cookieValue,expire?,path?,domain?,secure?) {
    try {
      this.cookies.set(cookieName, cookieValue,expire,path,domain,secure);
    } catch(e){
      console.log('Constant service .setCookieData - ',e);
      return null;
    }
  }

  getCookieData(cookieName): any {
    try {
      return this.cookies.get(cookieName);
    } catch(e){
      console.log('Constant service .getCookieData - ',e);
      return null;
    }
  }

  // SET GET Encrypt Decrypt Session Data

  setEncryptedSessionData(sessionName, sessionValue) {
    try {
      sessionStorage.setItem(sessionName, this.encryptionService.encryptUsingAES_256(sessionValue));
    } catch(e){
      console.log('Constant service .setEncryptedSessionData - ',e);
      return null;
    }
  }

  getDecryptedSessionData(sessionName) {
    try {
      return this.encryptionService.decryptUsingAES_256(sessionStorage.getItem(sessionName));
    } catch(e){
      console.log('Constant service .getDecryptedSessionData - ',e);
      return null;
    }
  }

  // SET GET Session Data

  setSessionData(sessionName, sessionValue) {
    try {
      sessionStorage.setItem(sessionName, sessionValue);
    } catch(e){
      console.log('Constant service .setSessionData - ',e);
      return null;
    }
  }

  getSessionData(sessionName):any {
    try {
      return sessionStorage.getItem(sessionName);
    } catch(e){
      console.log('Constant service .getSessionData - ',e);
      return null;
    }
  }

   // SET GET Encrypt Decrypt LocalStorage Data

   setEncryptedLocalStorageData(storageName, storageValue:any) {
    try {
      localStorage.setItem(storageName, this.encryptionService.encryptUsingAES_256(storageValue.toString().trim()));
    } catch(e){
      console.log('Constant service .setEncryptedLocalStorageData - ',e);
      return null;
    }
  }

    getDecryptedLocalStorageData(storageName):any {
    try {
      if(localStorage.getItem(storageName) != null){
        return this.encryptionService.decryptUsingAES_256(localStorage.getItem(storageName)).toString().trim();
      }
      
    } catch(e){
      console.log('Constant service .setDecryptedLocalStorageData - ',e);
      return null;
    }
  }

  // SET GET LocalStorage Data

  setLocalStorageData(storageName, storageValue) {
    try {
      localStorage.setItem(storageName, storageValue);
    } catch(e){
      console.log('Constant service .setLocalStorageData - ',e);
      return null;
    }
  }

  getLocalStorageData(storageName):any {
    try {
      return localStorage.getItem(storageName);
    } catch(e){
      console.log('Constant service .getLocalStorageData - ',e);
      return null;
    }
  }


}
