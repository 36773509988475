import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {
    private readonly SALT = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg==');
    private readonly ITERATIONS = 1000;

    encryptUsingAES256(inputData) {
      const keyvalue = environment.keyValue;     
      const ivValue = environment.ivValue;
    

      const _key = CryptoJS.enc.Hex.parse(keyvalue);
      const _iv = CryptoJS.enc.Hex.parse(ivValue);

      
      const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inputData), _key, {
          keySize: 128 / 8,
          iv: _iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      const encryptedOP = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
      return encryptedOP;
  }

    encryptUsingAES_256(input) {
        try {
            let _key = CryptoJS.enc.Hex.parse(environment.keyValue);
            let _iv = CryptoJS.enc.Hex.parse(environment.ivValue);
            let encrypted = CryptoJS.AES.encrypt(
              CryptoJS.enc.Utf8.parse(input), _key, {
                keySize: 128 / 8,
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
              });
            const encryptedData = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
            return encryptedData;
        } catch (e) {
          return null;
            console.log("Error in encryption - ",e);
        }
        
      }

      decryptUsingAES_256(input) {
        try {
            let _key = CryptoJS.enc.Hex.parse(environment.keyValue);
            let _iv = CryptoJS.enc.Hex.parse(environment.ivValue);
        
            const decrypted = CryptoJS.AES.decrypt(input, _key, {
              iv: _iv
          });
    
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (e) {
          return null;
            console.log("Error in decryption - ",e);
        }
        
      }



}
