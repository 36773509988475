import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from 'src/app/service/constant.service';
import { LoginApiService } from 'src/app/service/login-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-income-details',
  templateUrl: './update-income-details.component.html',
  styleUrls: ['./update-income-details.component.css']
})
export class UpdateIncomeDetailsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UpdateIncomeDetailsComponent>,
    private constantService :ConstantService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      // this.image = data.dataImage;
      dialogRef.disableClose = true;
  }
  options: FormGroup;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('always');
  form: FormGroup;
  defaultchecked =true;
  btndisabled = false;
  showincome :boolean;
  showemail : boolean;
  DeclarationList;
  emailbelowtorelation;
  selectedMobile: any;
  selectedEmail: any;
  clientCode:string;
  selectedincome:string;
  ngOnInit(): void {
       this.selectedincome ='Below 1Lac';
       let income =JSON.parse(this.constantService.getLocalStorageData('incomeproof'));
       let email =JSON.parse(this.constantService.getLocalStorageData('emailproof'));
       this.clientCode= this.constantService.getDecryptedLocalStorageData('clientCode');
       if(email ==true){
        //this.getDeclarationList();
            // this.DeclarationList = response.data;
            // this.emailbelowtorelation = response.data;
            this.selectedMobile = 'Self';
            this.selectedEmail = 'Self';
       }
       if(income == true && email ==true){
        this.showincome = true;
       }
       else if(income ==false && email ==true){
        this.showincome =false;
       }
       

      
  }

  acknowledgement(event): void{
    if(event.checked == true){
      this.btndisabled = false;
    }
    else{
      this.btndisabled = true;
    }
  }
  updateincodedata(){
    try{
      this.showincome =false;
      this.constantService.setLocalStorageData('incomeproof',false);
      console.log(this.selectedincome);
    }
    catch(e){

    }
  }
  async getDeclarationList() {
    await LoginApiService.getLoginApiService()
        .consentDeclarationList(environment.Activation_Ocpkey,this.clientCode,"Web", '2')
        .then((response) => {
            this.DeclarationList = response.data;
            this.emailbelowtorelation = response.data;
            this.selectedMobile = this.DeclarationList[0].Text;
            this.selectedEmail = this.emailbelowtorelation[0].Text;
        });
        
  }
  
  closeincomepopup(){
    this.dialogRef.close({selectedincome:this.selectedincome,event:'success'});
    this.constantService.setLocalStorageData('emailproof',false);
    this.constantService.setLocalStorageData('mobileproof',false);
  }
  MobilechangeDeclarationList(mobileval){
    try{
      this.selectedMobile = mobileval.value;
    }
    catch(e){

    }
  }
  EmailchangeDeclarationList(emailval){
    try{
      this.selectedEmail = emailval.value;
    }
    catch(e){

    }
  }
}
