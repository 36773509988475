import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { HelperService } from '../service/helper.service';
import { EncryptionService } from '../service/encryption.service';
import { UtilService } from '../service/util.service';
import { LoginApiService } from '../service/login-api.service';
import { ConstantService } from '../service/constant.service';
import { CookieService } from 'ngx-cookie-service';
import { LoaderComponent } from '../pre-login/loader/loader.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CarouselModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    HttpClientModule
  ],
  providers: [ConstantService, CookieService, DatePipe, HelperService, EncryptionService, UtilService, LoginApiService],
  exports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CarouselModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    LoaderComponent]
})
export class SharedModule { }
