// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  authBaseAPI : 'https://gateway.5paisa.in/AuthApi/',     //UAT Auth API
  pwaSpotbaseAPI : ' https://gateway.5paisa.in/pwaspot/',    //UAT PWASPOT API
  BoApi : 'https://gateway.5paisa.in/BoAPI/Common/', // UAT BoApi
  ocpApimSubscriptionKey_BoApi : '03c5c61036744bceb2e1e8cdf0af0779',  // UAT Key
  ocpApimSubscriptionKey: '2284d4215515451ca7266097726d8097',     //UAT API subscription key
  ocpApimSubKey: "4dddd59bdab77bace6189d001f96487e",
  apiHeadKey: '4dddd59bdab77bace6189d001f96487e',
  userId: 'Hek68PU5r76',
  password: 'D86utK8Mn7',
  Authorization : 'Basic NXBhaXNhVWF0OjVwYWlzYUB3ZWJVYXQ=',
  pwaAllowUsers : '0',
  keyValue : '842a357c4b385726090aa184b75b691075da95e6ddd4eb40', // For Crypto
  ivValue : '53471a3a3623160b53471a3a3623160b', // For Crypto
  AccountPending : 'https://eauatv3.5paisa.com/ClientLogin/VerifyClientLoginMobile',
  PWAUrl : 'https://pwaweb-uat.5paisa.com/#/activation', 
  cookieExpireTime : 30,
  tradestationUrl : 'https://tradestationuat.5paisa.in/ssologin',
  noOfDeviceBinded :3,
  logAPIUrl:'https://uatapi.5paisa.in/brokingcommonapi/api/login/v1/',
  PrivateChecksumkey:'MDwwDQYJKoZIhvcNAQEBBQADKwAwKAIhAKOYi/x3mmyg3WMdoWZCM8jH+PUGt6xcBbZYjRCvGi5jAgMBAAE=',
  BoApi_param_key :'AIzaSylzfrB818x55FASHX4JuGQciR9lv7q',
  ShowFnoPopUp: true,
  ActivationMoficationUrl :'https://gateway.5paisa.in/ModificationAPI/',
  ActivationUrl :'https://gateway.5paisa.in/5peaccountuat/',
  tokenUserName:'3pH1UiSyjsnh8hVq9PuRdFvzIhMz+3dsBagjmJpP8XkkoX5+f/5unYYmamsjZAmi',
  tokenPassword:'4AYM8OOag1zEHsDbz5bLAjUekzVYwpywlLotOEBffag=',
  Activation_Ocpkey:'20b999c638704f3ea252b1b5ced2eb4a',
  ActivationMerchentkey:'3s@nY!mNgOH9p0Td',
  Ocpkey_nominee:'f826c701af874507967c537de48bd25c',
  Optout_Url:'https://emodify.5paisa.com/customer-dashboard/client-profile?source=nominee_declaration&appsource=App&nomtype=opt_in',
  Activation_Ocp_key :'87b65866c33c41d6a4fc08e4c274b783'
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
