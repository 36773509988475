/* eslint-disable no-var */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { LoginApiService } from './login-api.service';
import { ConstantService } from './constant.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { RiskClosureDialogComponent } from '../pre-login/dialog-box/risk-closure-dialog/risk-closure-dialog.component';
import { UrgentNominationRegistrationComponent } from '../pre-login/dialog-box/urgent-nomination-registration/urgent-nomination-registration.component';
import { UpdateIncomeDetailsComponent } from '../pre-login/dialog-box/update-income-details/update-income-details.component';
import { VerifyPersonalDetailsComponent } from '../pre-login/dialog-box/verify-personal-details/verify-personal-details.component';
import { ThankYouComponent } from '../pre-login/dialog-box/thank-you/thank-you.component';

declare function service(): any;
declare const window: any;
@Injectable({
    providedIn: 'root'
})
export class UtilService {
    
    // private getPassedData = new BehaviorSubject<any>('default message');
    // setPassedData = this.getPassedData.asObservable();

    // private eventSubject = new BehaviorSubject<any>(undefined);
    // setEventSubject = this.getPassedData.asObservable();

    constructor(public constantService: ConstantService,
        public dialog: MatDialog) {}

    // triggerSomeEvent(param: any) {
    //     this.eventSubject.next(param);
    // }

    // getEventSubject(): BehaviorSubject<any> {
    //     return this.eventSubject;
    // }

    isValidDate(dateString) {
        // First check for the pattern
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

        // Parse the date parts to integers
        var parts = dateString.split('/');
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if (year < 1920 || year > 2200 || month == 0 || month > 12) return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }

    addForwardSlash(text: string) {
        // console.log(text);
        text = text.replace(/\D+/g, '');
        var newtext = text;
        if (text.length >= 2) {
            newtext = text.substring(0, 2) + '/';
            if (text.length < 4) {
                newtext += text.substring(2);
            }
        }
        if (text.length >= 4) {
            newtext += text.substring(2, 4) + '/';
        }
        if (text.length > 4) {
            newtext += text.substring(4);
        }

        return newtext;
    }
    
    nullOrEmptyCheck(val) {
        if (val == null || val == '' || val == undefined || val == 'undefined' || val == 'null') return true;
        else return false;
    }

    base64ToByteArray(base64String) {
        const byteCharacters = atob(base64String);
        const byteArray = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteArray);
    }

    ConvertTextToBase64(inputText) {
        const val = CryptoJS.enc.Utf8.parse(inputText);
        const base64 = CryptoJS.enc.Base64.stringify(val);
        return base64;
    }

    goToPrevPage() {
        window.history.back();
    }
    async logApiData(clientcode: string, methodName, request: string, response: string): Promise<void> {
        let checksumkey = this.GenerateCheckSum(clientcode + methodName);
        try {
            let objrequest = {
                head: {
                    appName: "CommonAPI",
                    osName: "Web",
                    appVersion: "1.0",
                    clientIP: "::1"
                },
                body: {
                    ClientCode: clientcode,
                    MethodName: methodName,
                    Request: request,
                    Response: response,
                    Checksum: checksumkey
                }
            }
            let basicAuth = "Basic " + "dGVzdDoxMjM0NQ==";
            LoginApiService.getLoginApiService()
                .InsertLogForApi(
                    basicAuth,
                    environment.ocpApimSubscriptionKey_BoApi,
                    objrequest
                )
                .then((response) => {

                });
        }
        catch (error) {
            console.log(error, "logApiData")
        }

    }
    GenerateCheckSum(parameters): string {
        const id = environment.PrivateChecksumkey + parameters;
        const md5Hash = CryptoJS.MD5(id);
        return md5Hash.toString().substr(0, 16).toUpperCase();
         
    }
    async GetClientPersonalDetails(Jwttoken: string, clientCode) {
        let personalinfodata;
        try {
            let objrequest = {
                "head": {
                    "appVer": "1.0",
                    "appName": "5P",
                    "osName": "WEB",
                    "key": environment.BoApi_param_key
                },
                "body": {
                    "clientcode": clientCode
                }
            }
            let Authorization = 'Bearer ' + Jwttoken;
            await LoginApiService.getLoginApiService()
                .ClientPersonalDetails(Authorization, environment.ocpApimSubscriptionKey_BoApi, objrequest)
                .then(async (response) => {
                    personalinfodata = response.data;
                    //return personalinfodata;
                });
            return personalinfodata;
        }
        catch (error) {
            return personalinfodata = null;
        }

    }
    async getIncomeRangeData(clientCode, Jwttoken){
        let incomedata = null;
        try {
            let objincome = {
                head: {
                    appVer: "1.0",
                    appName: "5P",
                    osName: "WEB",
                    key: environment.BoApi_param_key
                },
                body: {
                    clientcode: clientCode
                }
            }
            let Authorization = 'Bearer ' + Jwttoken;
            await LoginApiService.getLoginApiService()
                .incomerange(Authorization, environment.ocpApimSubscriptionKey_BoApi, objincome)
                .then(async (response) => {
                    incomedata = response;
                    return incomedata;
                });
            return incomedata;
        }
        catch (error) {
            return incomedata = null;
        }

    }
    GenerateCheckSumActivation(parameters): string {
        const id = environment.ActivationMerchentkey + parameters;
        const md5Hash = CryptoJS.MD5(id);
        return md5Hash.toString().substr(0, 16).toUpperCase();

    }
    async generateToken(clientcode) : Promise<string>{
        let token =null;
        try{
          const request = {
            RequestSource: "Web"
          };
          const Authorizationkey = this.getAuthKey();
         await LoginApiService.getLoginApiService()
          .ActivationGenerateToken(environment.Activation_Ocpkey,clientcode,'login','Web',Authorizationkey,request)
          .then(async(tokenresponse: any) => {
             if(tokenresponse.data != null){
              if(tokenresponse.data.head.Status ==1 && tokenresponse.data.body.Status=='1'){
                token =tokenresponse.data.body.Data;
              }
              return token;
             }
          });
          return token;
        }
        catch(e){
          console.log(e);
          return token =null;
        }
        
    }
    getAuthKey(): string {
        let authkey=null;
        try{
           authkey ='Basic ' + this.ConvertTextToBase64(environment.tokenUserName + ':' + environment.tokenPassword);
           return authkey;
        }
        catch(e){
          console.log(e,"getAuthKey");
          return authkey =null;
        }
    
    }

    CheckForAllPopUp(){
        try{
          let isNomineepopupRequired = this.constantService.getDecryptedLocalStorageData('NomineePop');
          let income =JSON.parse(this.constantService.getLocalStorageData('incomeproof'));
          let emailproof =JSON.parse(this.constantService.getLocalStorageData('emailproof'));
          let mobileproof =JSON.parse(this.constantService.getLocalStorageData('mobileproof'));
          let IsAllowNseDerivative = this.constantService.getSessionData("AllowNseDerivative") ==null ? 'N' :this.constantService.getSessionData("AllowNseDerivative");
          let returnUrl = this.constantService.getDecryptedLocalStorageData('returnUrl');
          
          if(income == false && (emailproof ==false || mobileproof == false) && (IsAllowNseDerivative =='N' || IsAllowNseDerivative =='No') && isNomineepopupRequired =='YES'){
            if (returnUrl != undefined || returnUrl != null) {
                window.location.href = returnUrl; // redirect user, where from he logged in
              }
              else {
                window.location.href = environment.tradestationUrl;
              }
            }
            else{
                if(income== true){
                    this.openIncomeproofPop_up();
                  }
                  else if(emailproof== true || mobileproof == true){
                    this.openIncomeproofPop_up();
                  }
                  else if(isNomineepopupRequired =='NO'){
                    this.openNomineePop_up();
                  }
                  else if(IsAllowNseDerivative =='YES' || IsAllowNseDerivative =='Y'){
                    this.openFNODialogPop_up(returnUrl);
                  }
            }

          
        }
        catch(e){
            console.log(e,"UtileService-CheckForAllPopUp");
        }
    }
    openFNODialogPop_up(returnUrl): void {
        const dialogRef = this.dialog.open(RiskClosureDialogComponent, {});
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'success') {
            if (returnUrl != undefined || returnUrl != null) {
              window.location.href = returnUrl; // redirect user, where from he logged in
            }
            else {
              window.location.href = environment.tradestationUrl;
            }
          }
        });
      }
      openNomineePop_up() : void{
        const dialogRef = this.dialog.open(UrgentNominationRegistrationComponent, {});
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
        });
      }
      openIncomeproofPop_up(): void{
        const dialogRef = this.dialog.open(UpdateIncomeDetailsComponent, {});
        dialogRef.afterClosed().subscribe(result => {
          if(result.event =='success'){
            this.constantService.setLocalStorageData('incomeproof',false); // make this false for live data;
            this.updateIncomeDeclaration(result.selectedincome);
            const confirmdialogref = this.dialog.open(ThankYouComponent)
            confirmdialogref.afterClosed().subscribe(result => {
                if(result =='success'){
                    this.CheckForAllPopUp();
                }
            })
          }
        });
      }
      
      updateIncomeDeclaration(selectedincome) : void{
        try{
            let clientCode= this.constantService.getDecryptedLocalStorageData('clientCode');
            let requestdata={
                head: {
                    appVer: "1.0",
                    appName: "5P",
                    osName: "WEB",
                    key: environment.BoApi_param_key
                },
                body: {
                    ClientCode: clientCode,
                    IncomeRange: selectedincome,
                    EmailId: "",
                    EmailConsent: "",
                    Mobile: "",
                    MobileConsent: ""
                }
            }
            let jwttoken =this.constantService.getDecryptedLocalStorageData('JwtToken');
            let Authorization = 'Bearer ' + jwttoken.replace('JwtToken=', '');
             LoginApiService.getLoginApiService()
            .updateIncomeRange(Authorization,environment.ocpApimSubscriptionKey_BoApi,requestdata)
            .then((updateresponse: any) => {
                this.logApiData(clientCode,'updateIncomeDeclaration',JSON.stringify(requestdata),JSON.stringify(updateresponse.data));
            });
        }
        catch(e){
            console.log(e);
        }
      }
}
