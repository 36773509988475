import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from 'src/app/service/constant.service';

@Component({
  selector: 'app-otp-successfully-verified',
  templateUrl: './otp-successfully-verified.component.html',
  styleUrls: ['./otp-successfully-verified.component.css']
})
export class OtpSuccessfullyVerifiedComponent {


  constructor(
    public dialogRef: MatDialogRef<OtpSuccessfullyVerifiedComponent>,
    private constantService : ConstantService,
    @Inject(MAT_DIALOG_DATA) public data: any){
    dialogRef.disableClose = true;
  }
  closepopup(){
    this.dialogRef.close('success');
    this.constantService.setEncryptedLocalStorageData('NomineePop','YES');
  }

}
