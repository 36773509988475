import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-closure-dialog',
  templateUrl: './risk-closure-dialog.component.html',
  styleUrls: ['./risk-closure-dialog.component.css']
})
export class RiskClosureDialogComponent {

  defaultchecked =true;
  btndisabled = false;
  constructor(
    public dialogRef: MatDialogRef<RiskClosureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      // this.image = data.dataImage;
      dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.defaultchecked =true;
    this.btndisabled = false
  }
  acknowledgement(event): void{
    if(event.checked == true){
      this.btndisabled = false;
    }
    else{
      this.btndisabled = true;
    }
  }
  confirmation(){
    this.dialogRef.close('success');
    
  }

}
