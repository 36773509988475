import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from 'src/app/service/constant.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent {

  constructor(
    public dialogRef: MatDialogRef<ThankYouComponent>,
    private constantService :ConstantService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      // this.image = data.dataImage;
      dialogRef.disableClose = true;
  }

  closepopup(){
    this.dialogRef.close('success');
  }

}
