<app-loader *ngIf="showLoader"></app-loader>
<div class="nomination">
    <div class="image-wrapper">
        <img src="https://images.5paisa.com/SPOT/login/img_nominee.png">
    </div>
    <div class="title-wrapper">
        <h3>URGENT: Nomination Registration</h3>
        <p>In view of the SEBI requirement, it is mandatory to do nominee registration in your demat account to continue
            trading in stock market.<br>
            You may choose to update the nominee details later by clicking on Opt-out.</p>
    </div>
    <div class="button-wrapper">
        <div class="button_wrap">
            <button class="btn-light" (click)="optOut_Nominee()" >OPT - OUT </button>
            <button class="btn-dark"(click)="updateNominee()">Update Details</button>
        </div>
    </div>
</div>