<div class="nomination">
    <div class="image-wrapper">
        <img src="https://images.5paisa.com/SPOT/login/success.png">
    </div>
    <div class="title-wrapper">
        <h3>OTP Successfully Verified</h3>
        <p>You have successfully opted out of the nominee details update process</p>
        <p> Note: You can update nominee details later as well by visiting the profile section.</p>
           
    </div>
    <div class="button-wrapper">
        <div class="button_wrap">
            <button class="btn-dark" (click)="closepopup()">Ok</button>
        </div>
    </div>
</div>