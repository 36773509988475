import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreLoginComponent } from './pre-login/pre-login.component';
import { FreshLoginComponent } from './pre-login/fresh-login/fresh-login.component';
import { OtpVerificationComponent } from './pre-login/otp-verification/otp-verification.component';
import { EnterPinComponent } from './pre-login/enter-pin/enter-pin.component';
import { SetupPinComponent } from './pre-login/setup-pin/setup-pin.component';
import { AppComponent } from './app.component';
import { LoginForNotRegisterUserComponent } from './pre-login/login-for-not-register-user/login-for-not-register-user.component';
import { LoginexistComponent } from './pre-login/loginexist/loginexist.component';
import { SharedModule } from './modules/shared.module';

const routes: Routes = [
  
  
  
//-----------------------------------------------
  { path: '',
  //component: PreLoginComponent
  loadChildren: () => import('./modules/prelogin.module').then((m)=> m.PreloginModule)
},
{ path: 'pre-login',
  loadChildren: () => import('./modules/prelogin.module').then((m)=> m.PreloginModule)
},
{ path: 'otp-verification',
loadChildren: () => import('./modules/otp-verification-component.module').then((m)=> m.OtpVerificationComponentModule)},
{ path: 'enter-pin',
loadChildren : () => import('./modules/enter-pin-component.module').then((m)=> m.EnterPinComponentModule)
},
{
  path: 'setup-pin',
   loadChildren: () => import('./modules/setup-pin-component.module').then((m)=> m.SetupPinComponentModule )
},
{ path: 'user-not-registered',
 loadChildren: () => import('./modules/login-for-not-register-user-component.module').then((m)=> m.LoginForNotRegisterUserComponentModule)},
  {
    path: 'totp-verification',
    loadChildren: () => import('./modules/totp.module').then((m) => m.TotpModule)
  },
  {
    path:'login-exist', component:LoginexistComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [SharedModule, RouterModule]
})
export class AppRoutingModule { }