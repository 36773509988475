<div class="two-fa" [ngClass]="{'hide' : showincome == false}">
    <h2>Update Income Details</h2>
    <hr>
    <div class="inner-wrapper">
        <div class="progress-steps">
            <div  class="step-count active">1
            </div>
            <div *ngIf="!showemail" class="step-count">2
            </div>
        </div>
        <p>As per the Regulatory Guidelines, your Annual Income/ Networth needs to be update every year. Please update
            your Annual Income/Networth.
        </p>
        <form class="income-form">
            <label for="cars">Gross Income P.A.</label>
            <mat-select [(value)]="selectedincome">
                <mat-option value="Below 1Lac">Below 1 Lac</mat-option>
                <mat-option value="1Lac-5Lac"> 1 Lac - 5 Lac</mat-option>
                <mat-option value="5Lac-10Lac">5 Lac - 10 Lac</mat-option>
                <mat-option value="10Lac-25Lac">10 Lac - 25 Lac</mat-option>
                <mat-option value="25Lac-1Cr">25 Lac - 1 Cr</mat-option>
                <mat-option value="Above 1Cr">Above 1 Cr</mat-option>
            </mat-select>
        </form>
        <div class="declaration">
            <mat-checkbox [checked]="defaultchecked" (change)="acknowledgement($event)" class="example-margin">I hereby confirm that my Income/ Networth range as on date is as
                selected above & that in case of any change in the same, I understand that it is my sole responsibility
                to intimate 5Paisa. I undertake to immediately modify the same with the 5Paisa. I further confirm that
                the details provided are true and any wrong declaration would lead to appropriate action.
            </mat-checkbox>
        </div>
        <div class="bottom-button-wrapper">
            <button class="btn-dark" [disabled]="btndisabled" (click)="updateincodedata();" >Proceed</button>
        </div>

    </div>
</div>
<div class="two-fa step-count-2" [ngClass]="{'hide' : showincome == true}">
    <h2>Verify Personal Details</h2>
    <hr>
    <div class="inner-wrapper">
        <div class="progress-steps">
            <div class="step-count completed">1
            </div>
            <div class="step-count active">2
            </div>
        </div>
        <h6>Please confirm that below details belongs to
        </h6>
        <form class="income-form">
            <label for="">7710005477</label>
            <select>
                <option>Self</option>
            </select>
            <!-- <mat-select [(value)]="selectedMobile" (selectionChange)="MobilechangeDeclarationList($event)">
                <mat-option *ngFor="let mobileConsentOption of DeclarationList" [value]="mobileConsentOption.Text">
                    {{ mobileConsentOption.Text }}
                </mat-option>
            </mat-select> -->
        </form>
        <form class="income-form">
            <label for="">email@email.com</label>
            <select>
                <option>Self</option>
            </select>
            <!-- <mat-select  [(value)]="selectedEmail" (selectionChange)="EmailchangeDeclarationList($event)">
                <mat-option *ngFor="let items of emailbelowtorelation" [value]="items.Text">
                    {{ items.Text }}
                </mat-option>
            </mat-select> -->
        </form>
        <div class="declaration">
        </div>
        <div class="bottom-button-wrapper">
            <button class="btn-dark" (click)="closeincomepopup()" >Confirm</button>
        </div>

    </div>
</div>