import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConstantService } from 'src/app/service/constant.service';

import { LoginApiService } from 'src/app/service/login-api.service';
import { UtilService } from 'src/app/service/util.service';
import { StoreDataServiceService } from 'src/app/store-data-service.service';
import { environment } from 'src/environments/environment';
import { OtpVerificationsComponent } from '../otp-verifications/otp-verifications.component';

@Component({
  selector: 'app-urgent-nomination-registration',
  templateUrl: './urgent-nomination-registration.component.html',
  styleUrls: ['./urgent-nomination-registration.component.css']
})
export class UrgentNominationRegistrationComponent {

  clientCode : string;
  showLoader :boolean =false;

  ngOnInit(): void {
    this.showLoader=false;
    this.clientCode = this.constantService.getDecryptedLocalStorageData('clientCode');
    
  }
  constructor(
    public dialogRef: MatDialogRef<UrgentNominationRegistrationComponent>,
    public utilService : UtilService,
    private constantService: ConstantService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    public storeDataServiceService :StoreDataServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      // this.image = data.dataImage;
      dialogRef.disableClose = true;
   }

  updateNominee(){
    window.location.href=environment.Optout_Url;
  }
  async optOut_Nominee(){
    this.showLoader=true;
    try{
      let token = await this.utilService.generateToken(this.clientCode);
      this.generateAndverifyOtp(token,'GenerateOTP');
    }
    catch(e){
      this.showLoader=false;
      console.log(e,"optOut_Nominee");
    }
  }
  
  generateAndverifyOtp(token,otptype){
    try{
      
      const date = new Date();
      const clientCode = this.clientCode;
      const todayDt = this.datepipe.transform(date, this.constantService.checksumDateFormate);
      const parameters = 'Web' +clientCode + otptype + 'Client' + todayDt;
      console.log(parameters);
      let checksumkey = this.utilService.GenerateCheckSumActivation(parameters);
          let objrequest ={
            head: {
                CheckSumValue: checksumkey,
                IPAddress: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP :this.storeDataServiceService.getIpAddress(),
                AppSource: "Web"
            },
            body: {
                ClientCode: this.clientCode,
                OTPType: otptype,
                OTP:"",
                UserType:"Client"
            }
           }
           const Auth ='123456789:' + token;
           const Authorizationkey = this.NomineegetAuthKey();
           LoginApiService.getLoginApiService()
            .nomineeOtp(Auth,environment.Activation_Ocpkey,'Web',Authorizationkey,objrequest)
            .then((otpResponse: any) => {
              if(otpResponse.data != null){
                if(otpResponse.data.head.Status =='0' && otpResponse.data.body.Status =='0'){
                  this.showLoader=false; // hide loader
                  this.dialogRef.close('success');
                  this.enterOptForNominee();
                }
                else if(otpResponse.data.head.Status =='1' && otpResponse.data.body.Status =='1'){
                  this.showLoader=false; // hide loader
                }
                else if(otpResponse.data.head.Status =='0' && otpResponse.data.body.Status =='1'){
                  console.log(otpResponse.data.body.Message);
                  this.showLoader=false; // hide loader
                }
              }
              
            })
            .catch(async (error) => {
                if (error.response.status == 403 || error.response.status == 401) {
                  await this.optOut_Nominee();
                  this.showLoader=false; // hide loader
                }
          });
    }
    catch(e){
      console.log(e)
    }
  }
  NomineegetAuthKey(): string {
    let authkey=null;
    try{
       authkey ='Basic ' + this.utilService.ConvertTextToBase64(environment.tokenUserName + ':' + environment.tokenPassword);
       return authkey;
    }
    catch(e){
      console.log(e,"NomineegetAuthKey");
      return authkey =null;
    }
    
  }
  enterOptForNominee(){
    try{
      const dialogRef = this.dialog.open(OtpVerificationsComponent, {});
      dialogRef.afterClosed().subscribe(result => {
        if(result =='success'){
          
        }
      });
    }
    catch(e){
      console.log(e,"enterOptForNominee");
    }
  }
 

}
