import { Component, OnInit} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StoreDataServiceService } from './store-data-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    //navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      760: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    nav: false
  };
  IpAddress = '';
  //public IpAddress : any;
  constructor(private storeDataServiceService : StoreDataServiceService){
    
  }
  ngOnInit() {
    this.storeDataServiceService.fetchData()
      .then((result) => {
        this.IpAddress = result;
        console.log('Ip from service :',this.IpAddress);
        this.storeDataServiceService.setIpAddress(this.IpAddress);
      })
      .catch((error) => {
        console.log('error',error)
       
      });

     

  }
  
  
}
