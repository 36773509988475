// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })

import { environment } from 'src/environments/environment';
import {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
  BasePath,
  Header,
  Path,
  Body,
  BaseService,
  ServiceBuilder,
  Response,
  Query
} from 'ts-retrofit';

export class LoginApiService extends BaseService {

  // constructor() { }

    private static loginApiService = new ServiceBuilder()
    .build(LoginApiService);
  
    static getLoginApiService(): LoginApiService {
      return this.loginApiService;
    }

    @POST(environment.pwaSpotbaseAPI + 'V3/CheckClientStatus')
    async checkClientStatus(@Header('Ocp-Apim-Subscription-Key') AuthType: string, @Body request: any): Promise<Response> {
        return <Response>{};
    }

    @GET(environment.pwaSpotbaseAPI + 'ClientLoginWOPassword')
    async clientLoginWOPassword(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Query('EmailId') EmailId: string,
        @Query('IsOnlyMF') IsOnlyMF: string,
        @Query('AppSource') AppSource: string,
        @Query('CompanyID') CompanyID: string,
        @Query('type') type: string,
        ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.authBaseAPI + 'V1/LoginViaOTPWithoutCookie')
    async loginviaOtpWithoutCookie(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string, 
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    
    @POST(environment.authBaseAPI + 'UserPinVerificationWithClientCode')
    async userpinverificationwithclientcode(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.authBaseAPI + 'ValidateQRCodeForLogin')
    async totpVerification(
        // @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.authBaseAPI + 'SetDeviceBinding')
    async setDeviceBinding(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Header('5pCookie') Cookie:string,
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.authBaseAPI + 'token/generate-customjwt-token')     
      async generateToken(
          @Header('Authorization') authorization: string,
          @Header('Ocp-Apim-Subscription-Key') AuthType: string,
          @Body request: any
      ): Promise<Response> {
          return <Response>{};
    }

    @POST(environment.authBaseAPI + 'GetOTPForLogin')
    async getOTPForLogin(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string, 
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string,
        @Body request: any
        ): Promise<Response> {
        return <Response>{};
    } 

    @POST(environment.authBaseAPI + 'V1/GetDeviceBindingStatus')
    async getDeviceBindingStatus(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string, 
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string,
        @Body request: any
        ): Promise<Response> {
        return <Response>{};
    } 


    @POST(environment.authBaseAPI + 'UserPinVerificationWithDevice')
    async userpinverificationwithdevice(@Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string,
     @Body request: any): Promise<Response> {
        return <Response>{};
    } 
    
    @POST(environment.authBaseAPI + 'V3/SetUserPin')
    async SetUserPin(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Header('5pcookie') Cookie :string,
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string, 
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }      

    @POST(environment.authBaseAPI + 'V1/LoginViaOTP')
    async loginViaOTP(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string, 
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string, 
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.BoApi + 'login-request')
    async loginRequest(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.BoApi + 'GetIncomeRangeAPI')
    async getIncomeRange(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.BoApi + 'ClientInfo')
    async getClientInfo(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }


    @POST(environment.BoApi + 'verify-login')
    async verifyLogin(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    // @POST('login')
    // async Login(
    //     @Header('Ocp-Apim-Subscription-Key') AuthType: string,
    //     @Body request: any
    // ): Promise<Response> {
    //     return <Response>{};
    // }

    @POST(environment.pwaSpotbaseAPI + 'V5/LoginRequestMobileNewbyEmail')
    async getLoginAPI(
        @Header('Ocp-Apim-Subscription-Key') authroizationKey: string,
        @Body Request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.authBaseAPI + 'ClientInfo')
    async clientInfo(
        @Header('Authorization') authorization: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.pwaSpotbaseAPI + 'ClientLoginRequest')
    async clientLoginRequest(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Header('UserID') UserID: string, 
        @Header('Password') Password: string,
        @Query('ClientCode') ClientCode: string,
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.BoApi + 'v1/get-domain-sso')
    async getDomainSso(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.BoApi + 'insert-clientSSO-details')
    async insertClientSSO_Details(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }

    @POST(environment.BoApi + 'validate-client-percentage ')
    async validateClientPercentage(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    @POST(environment.BoApi + 'InsertCommonReqResLog')
    async InsertLogForApi(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    @POST(environment.BoApi + 'ClientPersonalDetails')
    async ClientPersonalDetails(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    @POST(environment.BoApi + 'GetIncomeRangeAPI')
    async incomerange(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    @POST(environment.ActivationMoficationUrl + 'CheckIfNomineeExists')
    async nomineeDetails(
        @Header('AuthenticationKey') Authen :string,
        @Header('Ocp-Apim-Subscription-Key') OcpKey: string,
        @Header('ReqSource') ReqSource: string,
        @Header('Authorization') Authorization: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    @POST(environment.ActivationUrl +'GenerateToken')
    async ActivationGenerateToken(
        @Header('Ocp-Apim-Subscription-Key') OcpKey: string,
        @Header('ClientId') clientcode: string,
        @Header('AppName') appname: string,
        @Header('ReqSource') ReqSource: string,
        @Header('Authorization') Authorization: any,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    @POST(environment.ActivationUrl + 'SmsOtpForNominee')
    async nomineeOtp(
        @Header('AuthenticationKey') Authen :string,
        @Header('Ocp-Apim-Subscription-Key') OcpKey: string,
        @Header('ReqSource') ReqSource: string,
        @Header('Authorization') Authorization: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
    @GET(environment.ActivationUrl +'getConsentDeclarationList')
    async consentDeclarationList(
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Header('ClientId') ClientId: string,
        
        @Header('AppName') AppName: string,
        @Query('CompanyID') CompanyId: string
    ): Promise<Response> {
        return <Response>{};
    } 
    @POST(environment.BoApi + 'UpdateIncomeRange')
    async updateIncomeRange(
        @Header('Authorization') authorization: string,
        @Header('Ocp-Apim-Subscription-Key') AuthType: string,
        @Body request: any
    ): Promise<Response> {
        return <Response>{};
    }
  
}
