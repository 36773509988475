import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';



import { EnterPinComponent } from './pre-login/enter-pin/enter-pin.component';
import { SetupPinComponent } from './pre-login/setup-pin/setup-pin.component';
import { LoginexistComponent } from './pre-login/loginexist/loginexist.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginForNotRegisterUserComponent } from './pre-login/login-for-not-register-user/login-for-not-register-user.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TwoFactorDialogComponent } from './pre-login/dialog-box/two-factor-dialog/two-factor-dialog.component';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { RiskClosureDialogComponent } from './pre-login/dialog-box/risk-closure-dialog/risk-closure-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { UpdateIncomeDetailsComponent } from './pre-login/dialog-box/update-income-details/update-income-details.component';
import {MatSelectModule} from '@angular/material/select';
import { UrgentNominationRegistrationComponent } from './pre-login/dialog-box/urgent-nomination-registration/urgent-nomination-registration.component';
import { VerifyPersonalDetailsComponent } from './pre-login/dialog-box/verify-personal-details/verify-personal-details.component';
import { OtpVerificationsComponent } from './pre-login/dialog-box/otp-verifications/otp-verifications.component';
import { OtpSuccessfullyVerifiedComponent } from './pre-login/dialog-box/otp-successfully-verified/otp-successfully-verified.component';
import { ThankYouComponent } from './pre-login/dialog-box/thank-you/thank-you.component';
// import { TotpVerification2Component } from './pre-login/totp-verification2/totp-verification2.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginexistComponent,
    UpdateIncomeDetailsComponent,
    VerifyPersonalDetailsComponent,
    OtpVerificationsComponent,
    OtpSuccessfullyVerifiedComponent,
    ThankYouComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CarouselModule,
    MatDialogModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatSelectModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
