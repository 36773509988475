import {Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConstantService } from 'src/app/service/constant.service';
import { DataService } from 'src/app/service/data.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { LoginApiService } from 'src/app/service/login-api.service';
import { environment } from 'src/environments/environment';
import { AfterContentInit,AfterViewChecked, AfterViewInit, } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { MatDialog } from '@angular/material/dialog';
import { RiskClosureDialogComponent } from '../dialog-box/risk-closure-dialog/risk-closure-dialog.component';
import { UrgentNominationRegistrationComponent } from '../dialog-box/urgent-nomination-registration/urgent-nomination-registration.component';
import { UpdateIncomeDetailsComponent } from '../dialog-box/update-income-details/update-income-details.component';

import { VerifyPersonalDetailsComponent } from '../dialog-box/verify-personal-details/verify-personal-details.component';

declare function setCookie(cookieName,cookieValue)
@Component({
  selector: 'app-loginexist',
  templateUrl: './loginexist.component.html',
  styleUrls: ['./loginexist.component.css']
})
export class LoginexistComponent implements AfterViewInit {
  
  clientCodeFromStorage: any;
  userNameFromStorage: string;
  mobileNumberFromStorage: any;
  incorrectPIN = false;
  pinValue = '';
  pinError='';
  isTwoFA: boolean = true;
  returnUrl :string;
  showLoader :boolean =false;
  isNomineepopupRequired :string='';
  isFNOpopupRequired : string='';

  userDataObj: any = {
    isDeviceBinded: '',
    deviceId: '',
    clientCode: '',
    clientStatus: '',
    displayMobileNumber: '',
    authType: '',
    loginState: '',
    userFirstName: '',
    isTwoFA: this.isTwoFA
};

  @ViewChild('pinOne') pinOne: ElementRef;
  @ViewChild('pinTwo') pinTwo: ElementRef;
  @ViewChild('pinThree') pinThree: ElementRef;
  @ViewChild('pinFour') pinFour: ElementRef;
  @ViewChild('pinFive') pinFive: ElementRef;
  @ViewChild('pinSix') pinSix: ElementRef;
  @ViewChild('btnVerified') btnVerified: ElementRef;

  loginExist = this.fb.group({
    clientCode: [null],
    userName: [null], 
    pinDigitOne: [null, Validators.required],
    pinDigitTwo: [null, Validators.required],
    pinDigitThree: [null, Validators.required],
    pinDigitFour: [null, Validators.required],
    pinDigitFive: [null, Validators.required],
    pinDigitSix: [null, Validators.required]   
  });


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private encryptionService: EncryptionService,
    private constantService: ConstantService,
    private dataService :DataService,
    private utilService : UtilService,
    public dialog: MatDialog
    
  ) {}

  ngAfterViewInit() {this.pinOne.nativeElement.focus()}

  ngOnInit(): void {
    this.isNomineepopupRequired = this.constantService.getDecryptedLocalStorageData('NomineePop');
    this.clientCodeFromStorage = this.constantService.getDecryptedLocalStorageData('clientCode');
    this.userNameFromStorage = this.constantService.getDecryptedLocalStorageData('ClientName');
    this.mobileNumberFromStorage = this.constantService.getDecryptedLocalStorageData('mobileNumber');
    this.returnUrl = this.constantService.getDecryptedLocalStorageData('returnUrl');
    
    this.dataService.data.subscribe((result) => {
      if(result.result =='success'){
        this.isNomineepopupRequired ='YES';
        this.utilService.CheckForAllPopUp();  
      }
      if(result!=''){
        this.userDataObj.deviceId =result.data.deviceId;
        this.userDataObj.noOfDeviceBinded = result.data.NoOfDeviceBinded;
      }
      else{
        this.router.navigateByUrl('pre-login');
      }
    });

    
    
  }
  

  onKeyUpHandleFocus(event, index) : void{
    try{
      this.incorrectPIN = false;
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode == 8 || charCode == 46 || charCode == 92 || charCode == 32) {
        this.reverseFocusInput(event, index);
      } else {
        this.focusInput();
      }
      if(index ==5 && event.code !='Backspace'){
        this.btnVerified.nativeElement.focus();
        this.verifyPin();
      }
      
    }
    catch(error){
      console.log(error)
      
    }
    
  }

  focusInput() {
    if (this.pinOne.nativeElement.value != '') {
      this.pinTwo.nativeElement.focus();
    }
    if (this.pinTwo.nativeElement.value != '') {
      this.pinThree.nativeElement.focus();
    }
    if (this.pinThree.nativeElement.value != '') {
      this.pinFour.nativeElement.focus();
    }
    if (this.pinFour.nativeElement.value != '') {
      this.pinFive.nativeElement.focus();
    }
    if (this.pinFive.nativeElement.value != '') {
      this.pinSix.nativeElement.focus();
    }
  }

  reverseFocusInput(event,index):void {
    try
    {
      var targetElement = event.target as HTMLInputElement;
      var prevElement = targetElement.previousSibling as HTMLInputElement;
      if (index > 0) {
        prevElement.focus();
      }
    }
    catch(error){
      console.log(error);
    }
  }

  verifyPin() :void {
    try{
      this.showLoader =true;
      this.pinOne = this.loginExist.controls['pinDigitOne'].value;
      this.pinTwo = this.loginExist.controls['pinDigitTwo'].value;
      this.pinThree = this.loginExist.controls['pinDigitThree'].value;
      this.pinFour = this.loginExist.controls['pinDigitFour'].value;
      this.pinFive = this.loginExist.controls['pinDigitFive'].value;
      this.pinSix = this.loginExist.controls['pinDigitSix'].value;
  
      this.pinValue =
        this.pinOne.toString() +
        this.pinTwo.toString() +
        this.pinThree.toString() +
        this.pinFour.toString() +
        this.pinFive.toString() +
        this.pinSix.toString();
  
        var encryptPinVal =this.encryptionService.encryptUsingAES256(this.pinValue);
        const request = this.verifyMPinRequest(encryptPinVal);
  
      LoginApiService.getLoginApiService()
        .userpinverificationwithdevice(environment.ocpApimSubscriptionKey,environment.userId, environment.password, request)
        .then(async(pinVerificationResponse) => {
          this.utilService.logApiData(this.clientCodeFromStorage,'userpinverificationwithdevice',JSON.stringify(request),JSON.stringify(pinVerificationResponse.data));
          if (pinVerificationResponse.data.body.Status == 0 &&pinVerificationResponse.data.head.status == 0) {
          if(environment.ShowFnoPopUp = true){ //Check this Param from firebase
            let responsePersonalData = await this.utilService.GetClientPersonalDetails(pinVerificationResponse.data.head.JwtToken,this.clientCodeFromStorage);
            //this.utilService.logApiData(this.clientCodeFromStorage,'GetClientPersonalDetails','',JSON.stringify(responsePersonalData));
            if(responsePersonalData != null){
              //   let data ={
              //     "head": {
              //         "Status": "0",
              //         "Type": "Executed"
              //     },
              //     "body": {
              //         "Message": "Success",
              //         "clientInfoResult": {
              //             "ClientInfo": [
              //                 {
              //                     "cm_IsNSECurrency": "N",
              //                     "cm_IsBSECurrency": "N",
              //                     "cm_IsNSECash": "Y",
              //                     "cm_IsBSECash": "Y",
              //                     "cm_IsBSEFO": "N",
              //                     "cm_IsNSEFO": "N",
              //                     "cm_IsNSEMF": "Y",
              //                     "cm_IsBSEMF": "Y",
              //                     "Br_POA": "N",
              //                     "cm_cd": "52361347",
              //                     "cm_dob": "1990-08-23T00:00:00",
              //                     "cm_name": "AAKASH MANGESH SAKPAL",
              //                     "cm_panno": "CGHPS8284B",
              //                     "br_add1": "Sagar B 13 Pandit Wadi Near|Fish Market Virar||Mumbai|Maharashtra",
              //                     "br_tele1": "",
              //                     "br_mobile": "9029920182",
              //                     "br_email": "SAKPALAAKASH@GMAIL.COM",
              //                     "PinCode": "401303",
              //                     "CM_SqOffOnly": "N",
              //                     "alb": -235.0300,
              //                     "SPAN": 0.0000,
              //                     "THV": 28.1000,
              //                     "UNCLCHQ": 0.0000,
              //                     "GHVC": -100.0000,
              //                     "NETWORTH": -206.9300,
              //                     "Rank": "NEED LOGIC",
              //                     "Gender": "M",
              //                     "CPCode": "52361347",
              //                     "SchemeName": "",
              //                     "cm_opendt": "2021-09-20T15:46:03.387"
              //                 }
              //             ]
              //         }
              //     }
              // }
              //responsePersonalData = data;
              if(responsePersonalData.body.clientInfoResult.ClientInfo.length > 0){
                if(responsePersonalData.body.clientInfoResult.ClientInfo[0].br_mobile !=null || responsePersonalData.body.clientInfoResult.ClientInfo[0].br_mobile != undefined || responsePersonalData.body.clientInfoResult.ClientInfo[0].br_mobile != ''){
                  this.constantService.setEncryptedLocalStorageData('MobileNumber',responsePersonalData.body.clientInfoResult.ClientInfo[0].br_mobile);
                }
                if(responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_IsBSEFO =='Y' || responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_IsNSEFO =='Y'){
                  this.isFNOpopupRequired ='YES';
                  this.constantService.setSessionData("AllowNseDerivative","Y");
                }
              }
              else{
                this.isFNOpopupRequired ='NO';
                this.constantService.setSessionData("AllowNseDerivative","N");
              }
              
            }
          }
            
            this.incorrectPIN = false;
            setCookie('JwtToken',pinVerificationResponse.data.head.JwtToken);
            setCookie('5paisacookie',pinVerificationResponse.data.head._5paisacookie); // set Cookie
            this.constantService.setEncryptedLocalStorageData('5Pcookies','5paisacookie='+pinVerificationResponse.data.head._5paisacookie); // set in local storage
            this.constantService.setEncryptedLocalStorageData('SolaceCred',pinVerificationResponse.data.head.SolaceCred); // set in local storage
            this.constantService.setEncryptedLocalStorageData('JwtToken','JwtToken='+pinVerificationResponse.data.head.JwtToken);
            setCookie('SolaceCred',pinVerificationResponse.data.head.SolaceCred); // set Cookie
            let responseincome = await this.utilService.getIncomeRangeData(this.clientCodeFromStorage,pinVerificationResponse.data.head.JwtToken);
            if(responseincome != null){
                if(this.constantService.showIncomePopup == true && this.constantService.showMobileEmailPopup ==true){ // for UAT
                  this.constantService.setLocalStorageData('incomeproof',this.constantService.showIncomePopup);
                  this.constantService.setLocalStorageData('emailproof',this.constantService.showMobileEmailPopup);
                  this.constantService.setLocalStorageData('mobileproof',this.constantService.showMobileEmailPopup);
                }
                else{
                  this.constantService.setLocalStorageData('incomeproof',responseincome.data.body.isIncomeRangeConsentRequired);
                  this.constantService.setLocalStorageData('emailproof',responseincome.data.body.isEmailConsentRequired);
                  this.constantService.setLocalStorageData('mobileproof',responseincome.data.body.isMobileConsentRequired);
                }
                
            }
            else{
              // if income data is null
              this.constantService.setLocalStorageData('incomeproof',false);
              this.constantService.setLocalStorageData('emailproof',false);
              this.constantService.setLocalStorageData('mobileproof',false);
             }
            this.showLoader =false;          
            // if (this.returnUrl != null) {
            //   window.location.href = this.returnUrl; // redirect user, where from he logged in
            // }
            // else {
            //   window.location.href = environment.tradestationUrl;
            // }
            // this.CheckForAllPopUp();
            this.utilService.CheckForAllPopUp();
          } else if (
            pinVerificationResponse.data.body.Status == 1 &&
            pinVerificationResponse.data.head.status == 0
          ) {
               this.showLoader =false;  
               this.incorrectPIN = true;
               this.pinError = pinVerificationResponse.data.body.Message;
            // this.clearData();
          }
        });
    }
    catch(e){
      this.showLoader =false;
      console.log(e,"verifyPin")
    }
  }

  verifyMPinRequest(mpinval) {
    const request = {
      head: {
        appName: "5PTRADE",
        appVer: "3.0",
        key: environment.ocpApimSubKey,
        osName: "Web",
        requestCode: "5PUPVV2"
    },
      body: {
          ClientCode: this.clientCodeFromStorage.toString().trim(),
          DeviceID: this.userDataObj.deviceId,
          Pin: mpinval,
          DeviceWithSMS: 'Y',
          PublicIP: '10.123.215.130',
          MobileNo: this.mobileNumberFromStorage,
          RequestType: 'AuthForLogin'
      }
  
      
    }
    return request;
  }

  forgotMPIN() {
    this.showLoader =true;
    this.userDataObj.loginState = 'ForgotMPIN';
    this.userDataObj.isDeviceBinded =localStorage.getItem('isDeviceBinded');
    this.userDataObj.isTwoFA = localStorage.getItem('isTwoFA');
    this.dataService.sendData({
      data: this.userDataObj
    });
    //should go to otp verification for 2FA Authentication
    this.router.navigateByUrl('otp-verification');
  }
  changeLogin():void{
    this.constantService.setEncryptedLocalStorageData('changeuser',true);

    this.router.navigate(["pre-login"]);
  }

}
