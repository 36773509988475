<div class="two-fa">
    <h2>Verify Personal Details</h2>
    <hr>
    <div class="inner-wrapper">
        <div class="progress-steps">
            <div class="step-count completed">1
            </div>
            <div *ngIf="emailproof || mobileproof" class="step-count active">2
            </div>
        </div>
        <h6>Please confirm that below details belongs to
        </h6>
        <form class="income-form">
            <label for="">7710005477</label>
            <select>
                <option>Self</option>
                <option>Below 5 Lac</option>
                <option>Mercedes</option>
                <option>Audi</option>
            </select>
        </form>
        <form class="income-form">
            <label for="">email@email.com</label>
            <select>
                <option>Self</option>
                <option>Below 5 Lac</option>
                <option>Mercedes</option>
                <option>Audi</option>
            </select>
        </form>
        <div class="declaration">
        </div>
        <div class="bottom-button-wrapper">
            <button class="btn-dark" (click)="updatepersonaldata()" >Confirm</button>
        </div>

    </div>
</div>