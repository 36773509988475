<div class="two-fa">
    <h2>Thank You</h2>
    <hr>
    <div class="inner-wrapper">
        <div class="progress-steps">
            <div class="step-count completed">1
            </div>
            <div class="step-count completed">2
            </div>
        </div>
        <div class="image-wrapper">
            <img src="https://images.5paisa.com/SPOT/login/thank_you.gif">
        </div>
        <h6>Thank you for declaring your details </h6>

        <div class="declaration">
        </div>
        <div class="bottom-button-wrapper">
            <button class="btn-dark" (click)="closepopup()">Close</button>
        </div>

    </div>
</div>